<template>
  <!-- Icons -->
  <div class="background">
    <Reviews></Reviews>
    <SmartSizeFooter></SmartSizeFooter>
  </div>
  <!-- Icons -->
</template>

<script>
import Reviews from "@/components/Reviews/Reviews.vue";
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

export default {
  data() {
    return {};
  },
  components: {
    Reviews,
    SmartSizeFooter,
  },
};
</script>

<style>
.background {
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>