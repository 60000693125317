<template>
  <v-main>
    <v-container d-flex justify-center ><v-card class="pa-16" max-width="1800"><row class="d-flex justify-center"><img height="100" contain class="ma-1 d-flex justify-center" src="/smartsizetext.png"></img></row><v-row class="d-flex justify-center mb-10 mt-5 bold-text"><v-icon class="mr-4" >mdi-heart-outline</v-icon  >It takes less than 60 seconds to create a big smile </v-row>
      <v-row class="d-flex justify-center">         <v-card
        
        color="#F1F3F0"
    class="d-flex flex-column justify-space-between ma-5 pr-10"
    max-width="344"
    variant="outlined"
  >
  <v-card-item class="d-flex justify-center " >
    <v-img
      src="/illustration_welcome.png"
      width="100%"
    ></v-img>
  </v-card-item></v-card></v-row>
  <v-row></v-row>
    
 
  <v-card-actions>  <v-banner  v-if="has_not_rated_app">
  

  <template v-slot:actions>
    <v-rating
      v-model="appRating"
      color="yellow darken-3"
      background-color="#2A3027"
      empty-icon="$ratingFull"
      hover
    ></v-rating>
    <v-btn
      color="#42343A"
      text
      :disabled="!appRating"
      :href="urlAfterRating"
      target="_blank"
      @click="saveRating"
      ><a style="color: #42343a">Submit rating</a>
    </v-btn>
  </template>
</v-banner></v-card-actions>


</v-card>

  
  </v-card>




</v-container>
  </v-main>
</template>


<script>
export default {
  components: {
    Reviews: require("@/components/Reviews/Reviews.vue").default,
  },
  methods: {
    alert() {
      alert("Hello, World!");
    },
    saveRating() {
      this.$store.commit("updateShopProfile", {
        ...this.$store.getters.getShopProfile,
        app_rating: this.appRating,
        has_rated_app: true,
      });
      this.$store.commit("saveShopProfile", {
        app_rating: this.appRating,
        has_rated_app: true,
      });
    },
  },
  computed: {
    appRating: {
      get() {
        return this.$store.getters.getShopProfile.app_rating;
      },
      set(val) {
        this.$store.commit("updateShopProfile", {
          ...this.$store.getters.getShopProfile,
          app_rating: val,
        });
      },
    },
    has_not_rated_app() {
      return !this.$store.getters.getShopProfile.has_rated_app;
    },
    urlAfterRating() {
      return this.appRating < 5
        ? "https://docs.google.com/forms/d/e/1FAIpQLSfo4KleJ1RPFsIciRVG8oPq7-HwVPx93_Zaew-xmLpTmnVf0Q/viewform?embedded=true"
        : "https://apps.shopify.com/smartsize?locale=en#modal-show=ReviewListingModal";
    },
  },
};
</script>
